import Link from "next/link";
import { P } from "@components/common/text";
import Footer from "@components/Footer";
import logo from "../../assets/logo.png";

export default function Landing() {
  return (
    <>
      <div className="ph3 ph4-l mw8 center pb3 pb4-l pt4-l">
        <div className="flex items-center justify-center bb pb5">
          <section>
            <h1 className="f1 f-subheadline-l lh-title measure-narrow mb4">
              Find local, comparable data about commercial rental properties to
              support your next rent review.
            </h1>
            <img
              src={logo}
              className="db w-third mt4 mb3 mt5-l"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            />
            <P
              center
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            >
              Rent Check is a platform for members of the East End Trades Guild
              to share and view data about commercial rental properties in
              London.
            </P>
            <Link href="/login">
              <a className="no-underline f6 f5-ns db tc ph4 pv3 bg-animate bg-blue hover-bg-dark-blue white br2 mt4">
                Log in / register
              </a>
            </Link>
          </section>
        </div>
        <section className="mv5 bb pb5">
          <h2 className="lh-solid f2 f1-l">How to sign up</h2>
          <P className="mb4">
            <span className="f4 fl mr2 b">1.</span> This platform is exclusively
            for small business owners who are current members of the East End
            Trades Guild. If you&apos;re not a member already, you&apos;ll need
            to join us first.
          </P>
          <P className="mb4">
            <span className="f4 fl mr2 b">2.</span>Register for access to the
            Rent Check platform by clicking the button below.
          </P>
          <P className="mb4">
            <span className="f4 fl mr2 b">3.</span> Complete your registration
            by providing some basic information about yourself and your place of
            business.
          </P>
          <P className="mb4">
            <span className="f4 fl mr2 b">4.</span> Find your local
            representative and introduce yourself. Call us old fashioned, but we
            don&apos;t want a digital tool to replace face to face
            communication. We&apos;d like you to meet your local rep, who is
            helping us to fight for businesses like yours. They will verify your
            identity and allow you access to the platform.
          </P>
          <P className="">
            <span className="f4 fl mr2 b">5.</span> You&apos;ll have access to
            our map of rental data and you&apos;ll be able to support your
            community by adding your own.
          </P>
        </section>
        <section className="mv5">
          <h2 className="lh-solid f2 f1-l">What we do with your data</h2>
          <P className="">
            We&apos;re not Google. We ask for your data only so we can share it
            with your fellow small business owners. You retain full ownership of
            your data, we will never share it with any third party outside this
            application, and you can permanently remove it from our servers
            whenever you like. Read our{" "}
            <Link href="/privacy-policy">
              <a>Privacy Policy</a>
            </Link>{" "}
            for more information.
          </P>
        </section>
        {/* <section className="mv5">
        <h2 className="lh-solid f2 f1-l">Our Partners</h2>
        <P>Rent Check is supported by Hackney and Tower Hamlets councils.</P>
      </section> */}
        <section className="pb5">
          <article className="mw8 center br2 ba b--light-blue bg-lightest-blue">
            <div className="dt-ns dt--fixed-ns w-100">
              <div className="pa3 pa4-ns dtc-ns v-mid">
                <div>
                  <h2 className="fw4 blue mt0 mb3">Register for access now</h2>
                  <p className="black-70 measure lh-copy mv0">
                    Already a Guild member? Create your Rent Check account and
                    start the registration process now.
                  </p>
                </div>
              </div>
              <div className="pa3 pa4-ns dtc-ns v-mid">
                <Link href="/login">
                  <a className="no-underline f6 f5-ns tc db w-100 pv3 bg-animate bg-blue hover-bg-dark-blue white br2">
                    Register for access
                  </a>
                </Link>
              </div>
            </div>
          </article>
        </section>
      </div>
      <Footer />
    </>
  );
}
