import { Form, Header } from "./Form.style";
import { Row, Col, Collapse } from "antd";
import FormItem from "@components/common/FormItem";
import Button from "@components/common/Button";
import { H1, P } from "@components/common/text";

const { Panel } = Collapse;

export default function RenderForm({
  onSubmit,
  title,
  description,
  handleChange,
  formValues,
  validationErrors,
  formFields,
  isSubmitting,
  disabledStartDate,
  disabledEndDate,
  privacy,
}) {
  return (
    <Form onSubmit={onSubmit} className="f5">
      <Header>
        {title && <H1>{title}</H1>}
        {description && <P>{description}</P>}
      </Header>
      {privacy && (
        <Collapse accordion>
          <Panel header="Data and Privacy" key="1">
            {privacy}
          </Panel>
        </Collapse>
      )}

      <Row type="flex" justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {formFields.map(({ ...fields }) => {
            const showItem =
              fields.showIfField && fields.showIfValue
                ? formValues[fields.showIfField] == fields.showIfValue
                : true;
            if (showItem)
              return (
                <FormItem
                  key={fields.label}
                  handleChange={handleChange}
                  value={formValues[fields.name]}
                  error={validationErrors[fields.name]}
                  disabledStartDate={disabledStartDate}
                  disabledEndDate={disabledEndDate}
                  {...fields}
                />
              );
          })}
        </Col>
      </Row>

      <Button
        type="primary"
        text="Submit"
        loading={isSubmitting}
        style={{ margin: "0 auto" }}
      />
    </Form>
  );
}
