import React from "react";
import Link from "next/link";
import { Row, Col, Icon } from "antd";
import { Label } from "./FormItem.style";
import getInputComponent from "./inputTypes";

const FormItem = ({
  type,
  label,
  required,
  helpText,
  link,
  linkText,
  hidden,
  ...formProps
}) => {
  if (hidden) return null;
  const RequiredStar = () => (
    <span style={{ color: "red" }} className="f6">
      *
    </span>
  );
  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      style={{ margin: "1.5rem 0" }}
    >
      <Col span={24}>
        <Label>
          {type === "checkbox" && getInputComponent(type)(formProps)}
          {type === "checkbox" && " "}
          {label}
          {required && <RequiredStar />}
          {link && (
            <Link href={link} passHref>
              <a target="_blank" rel="noreferrer">
                {linkText ? linkText : <Icon type="link" className="ml2" />}
              </a>
            </Link>
          )}
        </Label>
        {helpText && <p>{helpText}</p>}
        {type !== "checkbox" && getInputComponent(type)(formProps)}
        <div key={formProps.label} style={{ color: "var(--warning)" }}>
          {formProps.error}
        </div>
      </Col>
    </Row>
  );
};

export default FormItem;
