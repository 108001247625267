import { P, Label } from "@components/common/text";

export default function RenderFields({ fields }) {
  return fields.map(({ label, render, display, hideIf }) => {
    const displayProps = display ? display : { wrap: "true" };
    if (!hideIf) {
      return (
        render && (
          <P key={label} small {...displayProps}>
            <Label>{label}:</Label>
            {typeof render === "function" ? render() : render}
          </P>
        )
      );
    }
  });
}
