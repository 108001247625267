import React from "react";
import { Checkbox as AntCheckbox } from "antd";

const Checkbox = ({ name, handleChange, value }) => {
  return (
    <AntCheckbox
      name={name}
      checked={value}
      onChange={e => handleChange(name, e.target.checked)}
    />
  );
};

export default Checkbox;
