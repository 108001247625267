import Link from "next/link";
import PageLayout from "@components/common/PageLayout";
import { P } from "@components/common/text";
import Button from "@components/common/Button";

export default function Banned() {
  return (
    <PageLayout title="Your account has been blocked">
      <P>
        Your account has been blocked. Please contact your Street Rep for more
        details.
      </P>
      <P>
        If you wish to view or remove any of your personal data, please visit{" "}
        <Link href="/your-account">
          <a>your account</a>
        </Link>
        .
      </P>
      <Link href="/logout">
        <Button
          margin="2rem auto"
          type="primary"
          text="Log Out"
          loading={false}
        />
      </Link>
    </PageLayout>
  );
}
