import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { STATUS } from "@constants/user.json";
import VerifyEmail from "@components/VerifyEmail";
import Landing from "@components/Landing";
import AdditionalSignup from "@components/AdditionalSignUp";
import AwaitVerification from "@components/AwaitVerification";
import Banned from "@components/Banned";
import MapMap from "@components/Map";

/**

HOC to protect page level components by user role and status.

By default, this component will allow only logged in, verified users to access a page. Anyone else will be redirected. Users who have not completed signup (including banned users) will be shown the correct part of the signup process.

Optionally, you can specify an access level. Users at or above this access level will be able to access the page. Users who do not have sufficient access will be redirected to the map.

Banned users who have completed signup will be shown the Banned information page. If you wish to allow banned users access to a page, use the allowBanned prop. The page will still be restricted by level (e.g. if level is "super" and allowBanned prop is present, users below super level will still see the Banned page)

 */

export default function RestrictAccess({
  children,
  level = "user",
  allowBanned,
}) {
  const router = useRouter();
  const user = useSelector(state => state.user);

  // if user not logged in, redirect to landing page
  let RedirectComponent = Landing;
  let authorized = false;

  if (user && user.isAuthenticated) {
    // user is logged in, check if they are authorized based on role and status
    let status = STATUS.UNVERIFIED;
    // user who has not completed signup is always treated as unverified, even
    // if they have been banned.
    if (user.status.includes(STATUS.BANNED) && user.hasCompletedSignup) {
      status = STATUS.BANNED;
    }
    if (
      user.status.includes(STATUS.VERIFIED) &&
      !user.status.includes(STATUS.BANNED) &&
      !user.status.includes(STATUS.UNVERIFIED)
    ) {
      status = STATUS.VERIFIED;
    }

    switch (status) {
      // if unverified, check what stage of the signup process
      // to show
      case STATUS.UNVERIFIED:
        if (!user.emailVerified) {
          RedirectComponent = VerifyEmail;
        }
        if (user.emailVerified && !user.hasCompletedSignup) {
          RedirectComponent = AdditionalSignup;
        }
        if (user.emailVerified && user.hasCompletedSignup) {
          RedirectComponent = AwaitVerification;
        }
        break;
      // if verified, check if they have sufficient access, otherwise
      // redirect to the map
      case STATUS.VERIFIED:
        RedirectComponent = MapMap;
        switch (level) {
          case "user":
            authorized = true;
            break;
          case "super":
            if (user.isSuper || user.isAdmin) {
              authorized = true;
            }
            break;
          case "admin":
            if (user.isAdmin) {
              authorized = true;
            }
            break;
        }
        break;
      // if banned, check if this resource allows banned users, and
      // allow if they have sufficient access
      case STATUS.BANNED:
        RedirectComponent = Banned;
        if (allowBanned) {
          switch (level) {
            case "user":
              authorized = true;
              break;
            case "super":
              if (user.isSuper || user.isAdmin) {
                authorized = true;
              }
              break;
            case "admin":
              if (user.isAdmin) {
                authorized = true;
              }
              break;
          }
        }
        break;
    }
  }

  // replace the pathname when rendering on the client
  // we can't do this on the server as there is no router
  useEffect(() => {
    if (authorized) return;
    router.replace(router.pathname, "/", { shallow: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorized]);

  if (authorized) {
    return children;
  }

  return <RedirectComponent />;
}
