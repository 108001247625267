import React, { useEffect } from "react";
import Link from "next/link";
import { useSelector, useDispatch } from "react-redux";
import RenderForm from "@components/common/Form";
import { P, H3 } from "@components/common/text";
import * as signup from "@store/signup";
import formFields from "./formFields";
import validationSchema from "./validationSchema";

export default function EditUserData({
  title,
  description,
  callback,
  showTerms,
}) {
  const user = useSelector(state => state.user);

  const {
    _id,
    name,
    email,
    companyName,
    companyAddress: { addressLine1, addressLine2, city, postcode },
    membershipNumber,
    termsAgreed,
  } = user;

  const formOptions = {
    method: "patch",
    url: `/api/users/${_id}`,
    validationSchema,
    initialValues: {
      name,
      email,
      companyName,
      addressLine1,
      addressLine2,
      city,
      postcode,
      membershipNumber,
      agreeTerms: termsAgreed ? true : false,
    },
  };

  const dispatch = useDispatch();

  // initialise form with options on first render
  // empty array needed for dependencies as otherwise
  // infinite rerender loop occurs
  useEffect(() => {
    dispatch(signup.initForm(formOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { formValues, validationErrors, isSubmitting } = useSelector(
    state => state.signup,
  );

  const privacy = (
    <>
      <H3>Why we ask for this information</H3>
      <P>
        We want to know who is using our application so that we can keep our
        members and their data safe. The details you provide here will be seen
        by our Street Reps so that they can verify your account and manage your
        access to the app.
      </P>
      <P>
        For more information, please read our{" "}
        <Link href="/privacy-policy">
          <a>Privacy Policy</a>
        </Link>
        . If you have any further questions or concerns, please speak to a
        Street Rep near you.
      </P>
    </>
  );

  return (
    <RenderForm
      title={title}
      description={description}
      formFields={formFields(showTerms)}
      privacy={privacy}
      handleChange={(input, value) =>
        dispatch(signup.handleChange(input, value))
      }
      formValues={formValues}
      validationErrors={validationErrors}
      isSubmitting={isSubmitting}
      onSubmit={event => {
        event.preventDefault();
        dispatch(signup.submitForm(callback));
      }}
    />
  );
}
