const fields = [
  {
    label: "Full Name",
    name: "name",
    placeholder: "Your name",
    type: "text",
    required: true,
  },
  {
    label: "Company Name",
    name: "companyName",
    placeholder: "Company name",
    type: "text",
    helpText: "Please add the details for your main place of business",
    required: true,
  },
  {
    label: "Address line 1",
    name: "addressLine1",
    placeholder: "Line 1",
    type: "text",
    required: true,
  },
  {
    label: "Address line 2",
    name: "addressLine2",
    placeholder: "Line 2",
    type: "text",
  },
  {
    label: "City",
    name: "city",
    placeholder: "City",
    type: "text",
    required: true,
  },
  {
    label: "Postcode",
    name: "postcode",
    placeholder: "Postcode",
    type: "text",
    required: true,
  },
  {
    label: "Membership Number (if known)",
    name: "membershipNumber",
    placeholder: "Membership Number",
    type: "text",
  },
];

const terms = [
  {
    label: "I have read and agree to the ",
    name: "agreeTerms",
    link: "/terms-and-conditions",
    linkText: "Site Terms and Conditions",
    type: "checkbox",
  },
];

const termsHidden = [
  {
    label: "I have read and agree to the ",
    name: "agreeTerms",
    link: "/terms-and-conditions",
    linkText: "Site Terms and Conditions",
    type: "checkbox",
    hidden: true,
  },
];

export default showTerms =>
  showTerms ? fields.concat(terms) : fields.concat(termsHidden);
