import Link from "next/link";
import PageLayout from "@components/common/PageLayout";
import Button from "@components/common/Button";
import StreetRepsList from "@components/StreetRepsList";
import logo from "@assets/logo.png";

export default function AwaitVerification() {
  return (
    <PageLayout title="Registration Complete">
      <img
        src={logo}
        alt="East End Trades Guild and Guardians of the Arches logos"
        className="w5 mt3 mb4 db center"
      />
      <p>
        Your request for access to the RentCheck app has been received
        successfully. To keep our members&apos; data safe, we require all new
        users to be verified in person by a Street Rep. You can find your
        nearest Street Rep in the list below. Please organise an online or
        COVID-19 safe meeting with your Street Rep to continue your application.
      </p>
      <StreetRepsList />
      <div className="center tc">
        <Link href="/logout">
          <a>
            <Button
              type="primary"
              text="Log Out"
              loading={false}
              margin="0 auto 0 auto"
            />
          </a>
        </Link>
      </div>
    </PageLayout>
  );
}
