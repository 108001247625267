import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Collapse, List } from "antd";
import { fetchStreetReps } from "@store/reps";
import UserCard from "@components/UserCard";

const { Panel } = Collapse;

export default function StreetRepsList() {
  const dispatch = useDispatch();
  const { isLoading, reps } = useSelector(state => state.reps);
  useEffect(() => {
    dispatch(fetchStreetReps());
  }, [dispatch]);
  return (
    <Collapse accordion className="mb4">
      <Panel header="Find a Street Rep" key="1">
        <List
          loading={isLoading}
          itemLayout="horizontal"
          dataSource={reps}
          pagination="bottom"
          renderItem={rep => <UserCard user={rep} />}
        />
      </Panel>
    </Collapse>
  );
}
