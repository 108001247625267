import { Card, Tag, Icon } from "antd";
import Link from "next/link";
import RenderFields from "@components/common/RenderFields";

export default function UserCard({
  user: {
    _id,
    name,
    email,
    companyName,
    companyAddress: { addressLine1, addressLine2, city, postcode },
    status,
    role,
    streetRep,
    isBanned,
    membershipNumber,
  },
  ownDetails,
  admin = false,
  type,
  changeRep,
  adminUser,
}) {
  const address =
    addressLine1 &&
    `${addressLine1}${addressLine2 &&
      `, ${addressLine2}`}, ${city}, ${postcode}`;
  const StreetRep = () => {
    return (
      <>
        <Link href={`/control-panel/users/view?id=${streetRep._id}`}>
          <a>{streetRep.name} </a>
        </Link>
        {adminUser && adminUser.isAdmin && (
          <span
            className="ml2 f6"
            onClick={e => {
              e.preventDefault();
              changeRep();
            }}
          >
            <Icon type="edit" />
          </span>
        )}
      </>
    );
  };
  const fields = [
    {
      label: "Status",
      render:
        status &&
        status.map(s => (
          <Tag key={s} color={`var(--${status})`}>
            {s}
          </Tag>
        )),
    },
    { label: "Email", render: email, display: { truncate: true } },
    { label: "Company Name", render: companyName },
    { label: "Company Address", render: address },
    { label: "Membership Number", render: membershipNumber },
  ];
  const adminFields = [
    {
      label: "Role",
      render: <Tag color={`var(--${role})`}>{role}</Tag>,
    },
    {
      label: "Status",
      render:
        status &&
        status.map(s => (
          <Tag key={s} color={`var(--${status})`}>
            {s}
          </Tag>
        )),
    },
    { label: "Email", render: email, display: { truncate: true } },
    { label: "Company Name", render: companyName },
    { label: "Company Address", render: address },
    {
      label: "Street Rep",
      render: streetRep ? StreetRep : "None",
    },
    { label: "Membership Number", render: membershipNumber },
  ];
  let extra = undefined;
  if (admin && type !== "full") {
    extra = (
      <Link href={`/control-panel/users/view?id=${_id}`}>
        <a>View</a>
      </Link>
    );
  }
  if (ownDetails && !isBanned) {
    extra = (
      <Link href={`/your-account/user/edit`}>
        <a>Edit</a>
      </Link>
    );
  }
  return (
    <>
      <Card
        key={_id}
        title={name || email}
        type={type !== "full" && "inner"}
        extra={extra}
      >
        <RenderFields fields={admin ? adminFields : fields} />
      </Card>
    </>
  );
}
