import yup from "@utils/validator";

export default yup.object().shape({
  name: yup.string().required("Name is required"),
  addressLine1: yup.string().required("Address is required"),
  addressLine2: yup.string().trim(),
  city: yup.string().required("City is required"),
  companyName: yup.string().required("Company name is required"),
  postcode: yup
    .string()
    .postcode()
    .required("Postcode is required"),
  membershipNumber: yup.string().trim(),
  agreeTerms: yup
    .boolean()
    .oneOf([true], "Please read and accept the terms to continue"),
});
