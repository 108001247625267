import React, { useEffect } from "react";
import Link from "next/link";
import PageLayout from "@components/common/PageLayout";
import Button from "@components/common/Button";
import { P, H1 } from "@components/common/text";

export default function AwaitVerification() {
  useEffect(() => {
    fetch("/logout");
  }, []);
  return (
    <PageLayout>
      <H1 style={{ marginTop: "calc(50vh - 15rem)" }}>
        Verify your email address to continue
      </H1>
      <P>
        Please check your email, including spam folders, for a request from Rent
        Check to verify your email address. Once you have followed the link in
        the email, please log back in.
      </P>
      <Link href="/login">
        <Button
          margin="2rem auto"
          type="primary"
          text="Log In"
          loading={false}
        />
      </Link>
    </PageLayout>
  );
}
