import styled from "styled-components";

export const Label = styled.label.attrs({
  className: "ma0",
})`
  display: block;
  font-weight: bold;
  text-transform: capitalize;
`;

export const Form = styled.form`
  width: 100%;
  margin: 0 auto 0 auto;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
