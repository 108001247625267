import dynamic from "next/dynamic";

const Map = dynamic(
  () =>
    import(
      /* webpackChunkName: "rentCheckMapInterface" */
      "../MapInterface"
    ),
  {
    ssr: false,
  },
);

export default Map;
