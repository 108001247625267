import PageLayout from "@components/common/PageLayout";
import UserForm from "@components/UserForm";

export default function AdditionalSignUp() {
  return (
    <PageLayout title="Complete your registration">
      <UserForm
        showTerms
        description="Please complete your sign up by adding the following information. This will be used by our Street Reps to help verify your identity before allowing you access to the app and will not be visible to any other users, unless you choose to become a Street Rep yourself. Street Reps are trusted Guild members who've attended a training to support the wider membership."
      />
    </PageLayout>
  );
}
